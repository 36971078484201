<template>
  <div class="v-stack h-stretch gap-3">
    <div class="h-stack h-end">
      <button class="add" @click="$router.push('/finance/loans/add')">
        New Loan
      </button>
    </div>
    <a class="heading-title-2">Overview</a>
    <div class="card light mn">
      <div class="content h-stretch">
        <div class="v-stack h-stretch gap-3">
          <label class="text-left">Loans</label>
          <div class="text-left">{{ format.currency(totalLoans) }} kč</div>
          <label class="text-left">Paid Back</label>
          <div class="text-left">{{ format.currency(totalPaid) }} kč</div>
          <label class="text-left">Balance</label>
          <div class="text-left">{{ format.currency(balance) }} kč</div>
        </div>
      </div>
    </div>
    <div class="h-stack v-stretch h-space-between gap-3">
      <a class="heading-title-2">Loans</a>
      <div class="h-stack gap-3">
        <label style="align-self: center">Created:</label>
        <input type="checkbox" v-model="created" />
        <label style="align-self: center">Signed:</label>
        <input type="checkbox" v-model="signed" />
        <label style="align-self: center">Canceled:</label>
        <input type="checkbox" v-model="canceled" />
      </div>
    </div>
    <Table :items="filteredLoans" :defaultColumn="0" v-on:rowSelected="$router.push('/loans/detail/' + $event._id)"
      :columns="[
        {
          name: 'User',
          sort: 'alphabet',
          path: ['user', 'fullName'],
        },
        {
          name: 'Date',
          sort: 'date',
          path: ['startDate'],
          formatType: 'date',
          format: 'DD.MM.YYYY',
        },
        {
          name: 'Amount',
          sort: 'numeric',
          path: ['amount'],
          format: 'czk',
          formatType: 'currency',
        },
        {
          name: 'Paid',
          sort: 'numeric',
          path: ['paid'],
          format: 'czk',
          formatType: 'currency',
        },
        {
          name: 'Left To Pay',
          sort: 'numeric',
          path: ['leftToPay'],
          format: 'czk',
          formatType: 'currency',
          styleFunction: (item) => {
            if (item.state == 'signed') {
              if (item.leftToPay > 0) {
                return 'color: red;';
              } else {
                return 'color: green;';
              }
            }
            if (item.state == 'paid') {
              return 'color: green;';
            }
            return '';
          },
        },
        {
          name: 'Monthly',
          sort: 'numeric',
          path: ['monthlyRate'],
          format: 'czk',
          formatType: 'currency',
        },
        {
          name: 'Description',
          sort: 'alphabet',
          path: ['description'],
        },
        {
          name: 'State',
          sort: 'alphabet',
          path: ['state'],
        },
      ]"></Table>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
import { mapActions } from "vuex";
import utils from "@/utils.js";

export default {
  data() {
    return {
      loans: [],
      signed: true,
      canceled: false,
      created: true,
      format: utils.format,
    };
  },
  components: {
    Table,
  },
  computed: {
    filteredLoans() {
      let loans = this.loans;

      if (!this.signed) {
        loans = loans.filter((loan) => loan.state != "signed");
      }

      if (!this.created) {
        loans = loans.filter((loan) => loan.state != "created");
      }

      if (!this.canceled) {
        loans = loans.filter((loan) => loan.state != "canceled");
      }

      return loans;
    },
    totalLoans() {
      let total = 0;

      for (const loan of this.loans) {
        if (loan.state == "signed") {
          total += loan.amount;
        }
      }

      return total;
    },
    totalPaid() {
      let total = 0;

      for (const loan of this.loans) {
        if (loan.state == "signed") {
          total += utils.loanObject.amountPaid(loan);
        }
      }

      return total;
    },
    balance() {
      return this.totalPaid - this.totalLoans;
    },
  },
  methods: {
    ...mapActions(["getLoans"]),
  },
  mounted() {
    this.getLoans()
      .then((loans) => {
        this.loans = loans.map((loan) => {
          loan.paid = utils.loanObject.amountPaid(loan);
          loan.leftToPay = utils.loanObject.leftToPay(loan);
          return loan;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
